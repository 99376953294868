import React from 'react';
import {Spin} from 'antd';


export const Loading = (props: any) => (
    <Spin style={{
        position: "absolute",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    }}/>
);
