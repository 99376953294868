import i18nLib from "i18next"
import React, {useEffect, useState} from "react"
import {I18nextProvider, initReactI18next, useTranslation} from "react-i18next"
import {Loading} from "../components/Loading"
import translationRO from "../i18n/ro/default.json"
import termsRO from "../i18n/ro/terms.json"
import privacyRO from "../i18n/ro/privacy.json"

// Configuration Options
export const languages = {
    ro: {
        name: "Română",
    }
}


const i18nInit = i18nLib
  .use(initReactI18next)
  .init({
      lng: "ro",
      whitelist: Object.keys(languages),
      fallbackLng: "ro",
      ns: ["default", "terms", "privacy"],
      defaultNS: "default",
      // debug: true,

      interpolation: {
          escapeValue: false
      },
      resources: {
          ro: {
              default: translationRO,
              terms: termsRO,
              privacy: privacyRO
          }
      },
      react: {
          wait: true
      }
  })

// i18next wrapper provieds
interface i18NextWrapperProps {
    loading?: React.ReactElement;
    children: React.ReactElement;
}

export const I18NextWrapper = (props: i18NextWrapperProps) => {
    const {children} = props
    const [i18nLoading, seti18nLoading] = useState(true)
    const {i18n} = useTranslation()

    // Monitor the i18nInit
    useEffect(() => {
        i18nInit.then(() => seti18nLoading(false))
    }, [seti18nLoading])

    const loading = i18nLoading

    if (loading) {
    return props.loading ?? <Loading/>
  }

    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}
